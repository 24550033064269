import React, { useEffect, useState } from 'react';
import '../../shared/controls/checkboxInput.css';
import FormatHelper from '../../../helpers/FormatHelper';
import NoImageIcon from '../../../images/no-image.svg';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import CheckImage from '../../../images/check.svg';
import useBus from 'use-bus';
import CloseIcon from '../../../images/button-bin-slim.svg'


const PrivateTransfer = (props) => {
	const {
		transfer, index, selectedTransfer, selectTransfer
	} = props;

	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();

	let currencyCode = localStorage.getItem("CurrencyCode");
	let price = currencyCode === "EUR" ? transfer.priceEUR : currencyCode === "USD" ? transfer.priceUSD : transfer.price;

	// Not required for now
	/*useBus('Transfers.Booking', (payload) => {
		if (transfer.bookingLink === payload.resource && transfer.isChecked) {
			onClick();
		}
	});*/

	function onClick() {
		const isSelected = !transfer.isChecked;
		setIsLoading(true);
		transfer.isChecked = isSelected;
		props.modifyAction(transfer, isSelected).then(() => {
			resetForm(isSelected);
		})
	}
	function resetForm(value) {
		setIsLoading(false);
	}
	function onSelectTransfer(transfer) {
		selectTransfer(transfer);
	}
	let content = (

		<div className={"transfer-card " + (transfer.isChecked ? "transfer-card-selected" : "")} style={{ fontSize: "19px" }} >
			<label className="col-12 cardLabel d-flex" style={{ paddingTop: '5px', marginBottom: '0px', paddingLeft: '5px', paddingRight: '5px', justifyContent: 'center' }}>
				<span style={{ fontWeight: "600" }}>{t("TRANSFER_FOR") + " 1-" + transfer.leg1.maxCapacity}</span>
				<span class="pl-1">{t("PEOPLE")}</span>
				{transfer.isChecked && <div onClick={onClick} className={`pointer ml-auto`} style={{ position: "relative", marginRight: "-20px", marginTop: "-20px" }}>
					<img src={CloseIcon} alt="" />
				</div>}
			</label>
			<label className="col-12 cardLabel d-flex" style={{ marginBottom: '7px', paddingLeft: '5px', paddingRight: '5px', justifyContent: 'center' }}>
				<span className={"checkboxLabel"}>
					<span style={{ fontWeight: "600" }}>{FormatHelper.formatPrice(price)}</span>
					<span style={{ fontSize: "16px" }}>{" / " + t("RETURN")}</span>
				</span>
			</label>
			{!isLoading &&
				<div className="d-flex flex-row justify-content-center align-items-center w-100">
					<button onClick={() => onClick()} disabled={price <= 0} id={'transfer-checkbox_' + index} className={(transfer.isChecked ? "added-button " : "add-button ") + "mt-3 mb-1"}
					>
						{/*{transfer.isChecked ? t("ADDED") : t("VOUCHER_ADD_CONTROL")}*/}
						{transfer.isChecked ? "Selected" : t("VOUCHER_ADD_CONTROL")}
					</button>
				</div>
				||
				<>
					<div><Skeleton height={32} /></div>
				</>
			}
		</div>
	);

	return content;
}

export default PrivateTransfer;
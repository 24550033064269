import React from 'react';
import PaySecureCards from '../../../images/pay-secure-cards-no-dd.svg';
import PaySecureCardsDD from '../../../images/pay-secure-cards-dd.svg';
import { useTranslation } from 'react-i18next';
import cookieHelper from '../../../helpers/CookieHelper';

const SecurePayDisclaimer = () => {
	const { t } = useTranslation();
	const selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");
	const currencyCode = localStorage.getItem("CurrencyCode");
	return (
		<div className="w-100 mt-0 mb-4 mb-md-3">
			<div className="row">
				<div className="col-12 text-lg-left text-center">
					<img className="img-fluid pay-secure-cards" src={selectedLanguage === 'en' && currencyCode.toLowerCase() === 'gbp' ? PaySecureCardsDD : PaySecureCards} alt="Pay secure cards" />
				</div>
			</div>
		</div>
	)
}

export default SecurePayDisclaimer;